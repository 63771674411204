import { Response } from 'types/responses'
import { ApiClient } from 'utils/apiClient.utils'

export class SharedRareApi<TItemPms, TItemResp> {
  protected readonly apiClient: ApiClient = new ApiClient()
  protected readonly pathUrl: string

  // Create a new item
  async createItem(data: TItemPms): Promise<Response<TItemResp>> {
    return await this.apiClient.post<TItemPms, Response<TItemResp>>(this.pathUrl, data)
  }

  // Read an item
  async getItem(data: any): Promise<Response<TItemResp>> {
    return await this.apiClient.get<any, Response<TItemResp>>(this.pathUrl, data)
  }

  // Update an item
  async updateItem(data: TItemPms): Promise<Response<TItemResp>> {
    return await this.apiClient.patch<TItemPms, Response<TItemResp>>(this.pathUrl, data)
  }

  // Delete an item
  async deleteItem(data: any): Promise<void> {
    return await this.apiClient.delete<any, void>(this.pathUrl, data)
  }
}
