import { SharedRareApi } from './sharedRare.api'

export interface TUser {
  id: number
  email: string
  profile_types: string[]
}

export class UserApi extends SharedRareApi<TUser, TUser> {
  protected readonly pathUrl: string = '/common/user'
}
