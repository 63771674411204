import React from 'react'
import { Spin } from 'antd'

import './_loading-mask.scss'

const LoadingMask = () => (
  <div className='full-page-spiner'>
    <Spin size='large' />
  </div>
)

export default LoadingMask
