import { action, observable, makeObservable } from 'mobx'
import { Response } from 'types/responses'
import { setCookies, getCookies, removeCookies } from 'libs/cookies'
import * as api from '../api/session.api'

class SessionStore {
  storeName = ''
  childApi = new api.SessionApi()

  @observable loading = true
  @observable user: api.UserResp = {} as api.UserResp

  constructor() {
    makeObservable(this, null, { autoBind: true })
  }

  @action.bound
  async signIn(data: api.SignInParams) {
    this.toggleLoading(true)
    const resp = await this.childApi.signIn(data)
    this.setSession(resp)
    this.toggleLoading(false)
    return resp
  }

  @action.bound
  async signUp(data: api.SignUpParams) {
    this.toggleLoading(true)
    const resp = await this.childApi.signUp(data)
    this.setSession(resp)
    this.toggleLoading(false)
    return resp
  }

  @action.bound
  async refreshToken() {
    const refreshToken = getCookies('refreshToken')
    if (!refreshToken) {
      throw new Error('Refresh token not found')
    }

    this.toggleLoading(true)
    const resp = await this.childApi.refreshToken(refreshToken)
    this.setSession(resp)
    this.toggleLoading(false)
    return resp
  }

  @action.bound
  async revokeToken() {
    this.toggleLoading(true)
    const resp = await this.childApi.revokeToken()
    removeCookies('authToken')
    removeCookies('refreshToken')
    this.toggleLoading(false)
    return resp
  }

  @action.bound
  async userInfo() {
    this.toggleLoading(true)
    const resp = await this.childApi.info()
    this.setUser(resp)
    this.toggleLoading(false)
    return resp
  }

  @action.bound
  async resetPassword(data: api.ResetPasswordParams) {
    this.toggleLoading(true)
    const resp = await this.childApi.resetPassword(data)
    this.toggleLoading(false)
    return resp
  }

  @action.bound
  async submitPassword(data: api.SubmitPasswordParams) {
    this.toggleLoading(true)
    const resp = await this.childApi.submitPassword(data)
    this.toggleLoading(false)
    return resp
  }

  @action.bound
  async sendConfirmation(data: api.SendConfirmationParams) {
    this.toggleLoading(true)
    const resp = await this.childApi.sendConfirmation(data)
    this.toggleLoading(false)
    return resp
  }

  @action.bound
  async submitConfirmation(data: api.SubmitConfirmationParams) {
    this.toggleLoading(true)
    const resp = await this.childApi.submitConfirmation(data)
    this.toggleLoading(false)
    return resp
  }

  @action.bound
    setUser = (resp: Response<api.UserResp>) => {
      if (resp.status === 200 && resp.data) {
        this.user = resp.data
      }
    }

  @action.bound
    setSession = (resp: Response<api.SessionResp>) => {
      if ((resp.status === 200 || resp.status === 201) && resp.data.token) {
      // Update the authToken in cookies
        setCookies('authToken', resp.data.token, { maxAge: resp.data.expiresIn })
        setCookies('refreshToken', resp.data.refreshToken, { maxAge: 604800 }) // 604800 - 1 week in seconds
      }
    }

  @action.bound
    toggleLoading = value => {
      this.loading = value
    }
}

export default SessionStore
