import * as Yup from 'yup'
import isURL from 'validator/es/lib/isURL'
import isEmail from 'validator/es/lib/isEmail'
import isCreditCard from 'validator/es/lib/isCreditCard'
import isLength from 'validator/es/lib/isLength'
import isIP from 'validator/es/lib/isIP'
import isInt from 'validator/es/lib/isInt'

import { DATE_FORMATS } from 'constants/dateTime.constants'
import {
  MIN_PASSWORD_LENGTH,
  MAX_PROFILE_NAME_LENGTH,
} from 'constants/validator.constants'

import { formatToDefault } from 'utils/dateTime.utils'

// import { checkUsernameUniqueness } from 'containers/app/api/user.api'

export const isEmpty = (obj = {}) =>
  // additional check, because Object.keys(new Date()).length === 0;
  Object.keys(obj).length === 0 && obj.constructor === Object

export const isInteger = (value) => Number.isInteger(value)

export const isString = (value) => typeof value === 'string' || value instanceof String

export const isNumber = (value) => typeof value === 'number'

export const isBoolean = (value) => typeof value === 'boolean'

export const isObject = (value) => typeof value === 'object' && !Array.isArray(value) && value !== null

// export const validateUsername = async (value) => {
//   if (validateUsername.lastValue === value) {
//     return validateUsername.lastError
//   }
//   if (!value) {
//     return ('validations.empty')
//   }

//   validateUsername.lastValue = value
//   if (value.length < MIN_USERNAME_LENGTH) {
//     validateUsername.lastError = ('validations.username_too_small')

//     return validateUsername.lastError
//   }

//   if (value.length > MAX_USERNAME_LENGTH) {
//     validateUsername.lastError = ('validations.username_too_big')

//     return validateUsername.lastError
//   }

//   if (!USERNAME_REGEX.test(value)) {
//     validateUsername.lastError = ('validations.username_invalid')

//     return validateUsername.lastError
//   }

//   const response = await checkUsernameUniqueness((value || '').toLowerCase())
//   if (!response.valid) {
//     validateUsername.lastError = ('validations.username_exists')

//     return validateUsername.lastError
//   }

//   validateUsername.lastError = ''
//   return validateUsername.lastError
// }

const createDateValidator =
  (check) =>
    (current, date, currentFormat = DATE_FORMATS.DDMMYYYYHHmm) => {
      const formattedDate = date && formatToDefault(date, currentFormat)

      return !formattedDate || current[check](formattedDate)
    }

export const [dateIsAfter, dateIsBefore, dateIsSameOrBefore, dateIsSameOrAfter] = [
  'isAfter',
  'isBefore',
  'isSameOrBefore',
  'isSameOrAfter',
].map(createDateValidator)

export const getRequiredNumberField = () => Yup.number().required(('validations.required'))
export const getRequiredStringField = () => Yup.string().required(('validations.required'))
export const getRequiredObjectField = () => Yup.object().required(('validations.required'))

export const validVatId = Yup.string().test('validVatId', ('validations.invalid_vat'), (value) =>
  value ? isValidCampaignId(value) : true
)

export const requiredStringFieldDependsBoolean = (value) =>
  Yup.string().when(value, {
    is: true,
    then: () => Yup.string().required(('validations.required')),
  })

export const validEmailFieldOptional = Yup.string().email(('validations.invalid_email'))

// getValidEmailField
export const getValidEmailField = () =>
  Yup.string()
    .required(('validations.required'))
    .email(('validations.invalid_email'))

export const getValidPasswordField = () =>
  Yup.string()
    .required(('validations.required'))
    .min(MIN_PASSWORD_LENGTH, ('validations.password_too_short', { min: MIN_PASSWORD_LENGTH }))
export const getValidFirstNameField = () =>
  Yup.string()
    .required(('validations.required'))
    .max(MAX_PROFILE_NAME_LENGTH, ('validations.profile_name', { max: MAX_PROFILE_NAME_LENGTH }))
export const getValidLastNameField = () =>
  Yup.string()
    .required(('validations.required'))
    .max(MAX_PROFILE_NAME_LENGTH, ('validations.profile_name', { max: MAX_PROFILE_NAME_LENGTH }))
export const getValidUrlField = () =>
  Yup.string().required(('validations.required')).url(('validations.invalid_url'))

export const createValidationSchema = (object) => Yup.object().shape(object)

export const setRequiredStringFields = (names = []) =>
  names.reduce(
    (result, name) => ({
      ...result,
      [name]: Yup.string().required(('validations.required')),
    }),
    {}
  )

export const setRequiredStringFieldsWithDepends = (names = [], depends) =>
  names.reduce(
    (result, name) => ({
      ...result,
      [name]: requiredStringFieldDependsBoolean(depends),
    }),
    {}
  )

// temporary shitty solution, soon will implement better
// check if firstDate smaller or equal than secondDate
// required format for both dates - DDMMYYYY
export const dateIsSmallerOrEqual = (firstDate = '', secondDate = '') => {
  const [firstDateDays, firstDateMonths, firstDateYears] = firstDate.split('.')
  const [secondDateDays, secondDateMonths, secondDateYears] = secondDate.split('.')

  if (firstDateYears > secondDateYears) return false
  if (firstDateYears < secondDateYears) return true

  if (firstDateMonths > secondDateMonths) return false
  if (firstDateMonths < secondDateMonths) return true

  if (firstDateDays > secondDateDays) return false
  if (firstDateDays < secondDateDays) return true

  // case if firstDate === secondDate
  return true
}

export const isUrlValid = (url) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(url)
}

export const isValidClassName = (className, required = false) => {
  const pattern = new RegExp('^-?[_a-zA-Z]+[_a-zA-Z0-9-]*/?$')

  return className ? pattern.test(className) : !required
}

export const isValidColor = (color = '') => /^#[0-9a-f]{3}(?:[0-9a-f]{3})?$/i.test(color)

export const isHex = (value = '') => !Number.isNaN(Number(`0x${value}`))

export const checkButtonStatus = (isValid) => {
  if (isValid) {
    return false
  }
  return true
}

export const isValidCampaignId = (campaignId) => {
  const pattern = new RegExp(/^[A-Za-z]{2,4}(?=.{2,12}$)[-_ 0-9]*(?:[a-zA-Z][-_ 0-9]*){0,2}$/gm)
  return pattern.test(campaignId)
}

export const validators = {
  isEmail,
  isURL,
  isCreditCard,
  isLength,
  isIP,
  isInt,
}
