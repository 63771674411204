export const APP_ROOT_PATH = '/'
export const APP_USERS_CONFIRMATION_PATH = '/users/confirmation'
export const APP_USERS_CONFIRMATION_NEW_PATH = '/users/confirmation/new'
export const APP_USERS_SIGN_IN_PATH = '/users/sign_in'
export const APP_USERS_SIGN_UP_PATH = '/users/sign_up'
export const APP_USERS_PASSWORD_NEW_PATH = '/users/password/new'
export const APP_USERS_PASSWORD_EDIT_PATH = '/users/password/edit'
export const APP_USERS_CONGRADULATE = '/users/congradulate'

export const CABINET_ROOT = '/cabinet'
export const CABINET_PRODUCTS = '/cabinet/products'
export const CABINET_PRODUCT_DETAILS = '/cabinet/products/:id'
export const CABINET_PRODUCT_NEW = '/cabinet/products/new'
export const CABINET_PRODUCT_EDIT = '/cabinet/products/:id/edit'
export const CABINET_PAYERS = '/cabinet/payers'
export const CABINET_PAYER_DETAILS = '/cabinet/payers/:id'
export const CABINET_ORDERS = '/cabinet/orders'
export const CABINET_ORDER_DETAILS = '/cabinet/orders/:id'
export const CABINET_SELLER_SETTINGS = '/cabinet/settings'

export const CABINET_USER_PROFILE = '/cabinet/profile'

export const ADMIN_ROOT = '/admin'
export const ADMIN_PRODUCTS = '/admin/products'
export const ADMIN_PRODUCT_DETAILS = '/admin/products/:id'
export const ADMIN_USERS = '/admin/users'
export const ADMIN_USER_DETAILS = '/admin/users/:id'
export const ADMIN_SELLERS = '/admin/sellers'
export const ADMIN_SELLER_DETAILS = '/admin/sellers/:id'
export const ADMIN_MANAGERS = '/admin/managers'
export const ADMIN_MANAGER_DETAILS = '/admin/managers/:id'
export const ADMIN_PAYERS = '/admin/payers'
export const ADMIN_PAYER_DETAILS = '/admin/payers/:id'
export const ADMIN_ORDERS = '/admin/orders'
export const ADMIN_ORDER_DETAILS = '/admin/orders/:id'
export const ADMIN_USER_PROFILE = '/admin/profile'

export const SHOP_ROOT = '/shop/:username'
export const SHOP_PRODUCT_CHECKOUT = '/shop/:username/products/:slug/checkout'
export const SHOP_CONGRADULATE = '/shop/:username/orders/:order_token'
