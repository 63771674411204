import { makeObservable } from 'mobx'

import { setCookies } from 'libs/cookies'

import { APP_USERS_SIGN_IN_PATH } from 'constants/routes.constants'

import * as api from '../api/user.api'
import SharedRearStore from './sharedRear.store'

export class UserStore extends SharedRearStore<api.TUser> {
  storeName = 'UserStore'

  constructor() {
    super()
    makeObservable(this)

    this.childApi = new api.UserApi()
  }

  getCabinetLink(urlParams = '') {
    const { profile_types = [] } = this.item

    if (profile_types.indexOf('manager') >= 0) {
      setCookies('cabinet_profile_type', 'manager')
      return '/admin'
    }

    if (profile_types.indexOf('seller') >= 0) {
      setCookies('cabinet_profile_type', 'seller')
      return `/cabinet${urlParams}`
    }

    return `${APP_USERS_SIGN_IN_PATH}${urlParams}`
  }
}

export default UserStore
