export const globalColors = {
  lightGrayBg: '#F2F2F7',
  lightGrayText: 'rgba(0, 0, 0, 0.45)',
  black: 'rgba(0, 0, 0, 0.85)',
  purple: '#624BF5',
  greyBorder: '#D9D9D9',
  white: '#fff',
  red: '#FF4D4F',
  purpleSideItem: '#F3F1FF',
}
