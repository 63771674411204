import { action, observable, toJS, makeObservable } from 'mobx'
import { HTTPResponse } from 'types/responses'

class SharedRearStore<I extends Record<string, any>> {
  storeName = ''
  childApi = null

  @observable loading = true
  @observable item: I = {} as I
  @observable expands = []
  @observable counts = []

  constructor() {
    makeObservable(this, null, { autoBind: true })
  }

  @action.bound
  async getItem(data) {
    this.toggleLoading(true)
    const resp = await this.childApi.getItem({
      expand: toJS(this.expands),
      counts: toJS(this.counts),
      ...data,
    })
    this.setItem(resp)
    this.toggleLoading(false)
    return resp
  }

  @action.bound
  async createItem(data) {
    this.toggleLoading(true)
    const resp = await this.childApi.createItem(data || this.item)
    this.setItem(resp)
    this.toggleLoading(false)
    return resp
  }

  @action.bound
  async updateItem(data) {
    this.toggleLoading(true)
    const resp = await this.childApi.updateItem(data)
    this.setItem(resp)
    this.toggleLoading(false)
    return resp
  }

  @action.bound
  async deleteItem(data) {
    this.toggleLoading(true)
    const resp = await this.childApi.deleteItem(data)
    this.toggleLoading(false)
    return resp
  }
  // END of CRUD

  @action.bound
    toggleLoading = value => {
      this.loading = value
    }

  @action.bound
    setCounts = value => {
      this.counts = [...this.counts, ...value]
    }

  @action.bound
    setExpands = value => {
      this.expands = [...this.expands, ...value]
    }

  @action.bound
  setItem<T extends HTTPResponse>(res: T) {
    const { data, status } = res || {}
    if (status == 200 && data.data) this.item = data.data
  }

  @action.bound
  resetItem() {
    this.item = {} as I
  }
}

export default SharedRearStore
