import axios from 'axios'
import { Response } from 'types/responses'
import { ApiClient } from 'utils/apiClient.utils'
export interface ISellerAttributes {
  phone: string
  monthlySales: Array<string>
  countryCode: Array<string>
  firstName: string
  lastName: string
  username: string
  employeesCount: Array<string>
  usageReason: Array<string>
  companyRole: Array<string>
}

export interface SignUpParams {
  email: string
  password: string
  passwordConfirmation: string
  sellerAttributes: ISellerAttributes
}

export interface SignInParams {
  email: string
  password: string
}

export interface SessionResp {
  token: string
  refreshToken: string
  expiresIn: number
}

export interface UserResp {
  id: number
  email: string
}

export interface ResetPasswordParams {
  email: string
}

export interface SubmitPasswordParams {
  resetPasswordToken: string
  password: string
  passwordConfirmation: string
}

export interface SubmitConfirmationParams {
  confirmationToken: string
}

export interface SendConfirmationParams {
  email: string
}

export class SessionApi {
  private readonly pathUrl: string = '/users/tokens'
  private readonly apiClient: ApiClient = new ApiClient()

  async signUp(data: SignUpParams): Promise<Response<SessionResp>> {
    return await this.apiClient.post<SignUpParams, Response<SessionResp>>(`${this.pathUrl}/sign_up`, data)
  }

  async signIn(data: SignInParams): Promise<Response<SessionResp>> {
    return await this.apiClient.post<SignInParams, Response<SessionResp>>(`${this.pathUrl}/sign_in`, data)
  }

  async refreshToken(refreshToken: string): Promise<Response<SessionResp>> {
    return await axios.post(
      `${this.apiClient.baseURL}${this.pathUrl}/refresh`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      },
    )
  }

  async revokeToken(): Promise<void> {
    return await this.apiClient.post<void, void>(`${this.pathUrl}/revoke`)
  }

  async info(): Promise<Response<UserResp>> {
    return await this.apiClient.get<void, Response<UserResp>>(`${this.pathUrl}/info`)
  }

  async resetPassword(data: ResetPasswordParams): Promise<Response<UserResp>> {
    return await this.apiClient.post<ResetPasswordParams, Response<UserResp>>(`${this.pathUrl}/reset_password`, data)
  }

  async submitPassword(data: SubmitPasswordParams): Promise<Response<UserResp>> {
    return await this.apiClient.post<SubmitPasswordParams, Response<UserResp>>(`${this.pathUrl}/submit_password`, data)
  }

  async sendConfirmation(data: SendConfirmationParams): Promise<Response<UserResp>> {
    return await this.apiClient.post<SendConfirmationParams, Response<UserResp>>(
      `${this.pathUrl}/send_confirmation`,
      data,
    )
  }

  async submitConfirmation(data: SubmitConfirmationParams): Promise<Response<UserResp>> {
    return await this.apiClient.post<SubmitConfirmationParams, Response<UserResp>>(
      `${this.pathUrl}/submit_confirmation`,
      data,
    )
  }
}
