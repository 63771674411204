import axios from 'axios'
import { getAppConfig } from 'utils/config.utils'
import { getCookies, removeCookies } from 'libs/cookies'
import { APP_USERS_SIGN_IN_PATH } from 'constants/routes.constants'
import SessionStore from 'containers/shared/stores/session.store'
import { deepObjectPropNamesToCamelCase, deepObjectPropNamesToSnakeCase } from './nameStyle.utils'

export class ApiClient {
  public readonly baseURL: string = getAppConfig('apiPath')
  private readonly axiosConfig: any = {
    transformResponse: [
      data => {
        if (typeof data === 'string') {
          try {
            data = JSON.parse(data)
          } catch (e) {
            /* Ignore */
          }
        }

        return deepObjectPropNamesToCamelCase(data)
      },
    ],
  }

  private redirectToLogin<TResponse>() {
    removeCookies('authToken')
    removeCookies('refreshToken')
    if (window.location.pathname != APP_USERS_SIGN_IN_PATH) {
      window.location.href = APP_USERS_SIGN_IN_PATH
    }
    return { status: 301, error: 'need_login', data: {} } as TResponse
  }

  private async request<TRequest, TResponse>(
    method: string,
    url: string,
    data?: TRequest,
    params?: TRequest,
    config?: any,
  ): Promise<TResponse> {
    const headers: Record<string, string> = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Content-Type': 'multipart/form-data',
    }
    const authToken = getCookies('authToken')
    const refreshToken = getCookies('refreshToken')

    const transformedData = data ? deepObjectPropNamesToSnakeCase(data) : undefined

    if (authToken) {
      headers['Authorization'] = `Bearer ${authToken}`
    }

    try {
      const response = await axios.request({
        method,
        url,
        baseURL: this.baseURL,
        data: transformedData,
        params,
        headers,
        ...this.axiosConfig,
        ...config,
      })

      return response as TResponse
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response) {
        if (
          (error?.response?.status === 400 && error?.response?.data?.error == 'invalid_email') ||
          (error?.response?.status === 401 && error?.response?.data?.error == 'invalid_authentication')
        ) {
          return error?.response as TResponse
        } else if (error?.response?.status === 401 && error?.response?.data?.error == 'expired_token' && refreshToken) {
          // Token expired, attempt to refresh it
          const sessionStore: SessionStore = new SessionStore()
          const resp = await sessionStore.refreshToken()

          if (resp.data.token) {
            // Retry the original request with the new token
            const newHeaders = { ...headers, Authorization: `Bearer ${resp.data.token}` }
            return this.request<TRequest, TResponse>(method, url, data, params, { headers: newHeaders })
          } else {
            return this.redirectToLogin<TResponse>()
          }
        } else if (error?.response?.status === 404 || error?.response?.status === 500) {
          return { ...error?.response, data: {} } as TResponse
        }
      }
      // If token refresh fails or no token to refresh, redirect to login
      return this.redirectToLogin<TResponse>()
    }
  }

  async get<TRequest, TResponse>(url: string, params?: TRequest): Promise<TResponse> {
    return await this.request<TRequest, TResponse>('get', url, null, params)
  }

  async post<TRequest, TResponse>(url: string, data?: TRequest): Promise<TResponse> {
    return await this.request<TRequest, TResponse>('post', url, data)
  }

  async put<TRequest, TResponse>(url: string, data?: TRequest): Promise<TResponse> {
    return await this.request<TRequest, TResponse>('put', url, data)
  }

  async patch<TRequest, TResponse>(url: string, data?: TRequest): Promise<TResponse> {
    return await this.request<TRequest, TResponse>('patch', url, data)
  }

  async delete<TRequest, TResponse>(url: string, data?: TRequest): Promise<TResponse> {
    return await this.request<TRequest, TResponse>('delete', url, data)
  }
}
