export const ENVIRONMENT = {
  production: 'production',
  development: 'development',
  staging: 'staging',
}

export const CUSTOM_DOMAIN_PREFIX = 'pitaya.com'

export const ROOT_URL = '/'

export const STATES_BADGES_MAP = {
  payment_pending: 'processing',
  paying: 'success',
  paid: 'success',
  payment_refunded: 'warning',
  payment_chargebacked: 'warning',
  payment_not_completed: 'error',
}

export const STATES_TEXT_MAP = {
  payment_pending: 'Processing',
  paying: 'Paying',
  paid: 'Paid',
  payment_refunded: 'Refunded',
  payment_chargebacked: 'Chargebacked',
  payment_not_completed: 'Incomplete',
}
