import { apiVersion, apiHosts, webProtocols, webHosts, cookiesDomains } from 'libs/configs'

const appConfig = {
  apiVersion,
  mode: process.env.NODE_ENV,
  env: process.env.ENV,
  apiHost: apiHosts[process.env.ENV],
  apiPath: `${apiHosts[process.env.ENV]}/api/v1/`,
  webHost: webHosts[process.env.ENV],
  webProtocol: webProtocols[process.env.ENV],
  cookiesDomain: cookiesDomains[process.env.ENV],
  hostname: '',
}

type AppConfigKey = keyof typeof appConfig

export const getAppConfig = (key: AppConfigKey): string => appConfig[key]

export const setAppConfig = (key: AppConfigKey, value: string): void => {
  appConfig[key] = value
}
