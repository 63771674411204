import { ENVIRONMENT } from 'constants/general.constants'

import { getAppConfig } from 'utils/config.utils'


// TODO: Move env outside of function
export const isProduction = () => {
  const env = getAppConfig('env')
  return env === ENVIRONMENT.production
}

export const isStaging = () => {
  const env = getAppConfig('env')
  return env === ENVIRONMENT.staging
}

export const isDevelopment = () => {
  const env = getAppConfig('env')
  return env === ENVIRONMENT.development
}

export const isWindowEnv = () => typeof window !== 'undefined'
