export const apiVersion = '/v1'

export const apiHosts = {
  development: 'http://api.staging.pitayaapp.io',
  // development: 'http://localhost:3000',
  staging: 'http://api.staging.pitayaapp.io',
  // staging: 'https://54.196.207.200',
  production: 'https://api.pitayaapp.io',
}

export const webProtocols = {
  development: 'http',
  staging: 'https',
  production: 'https',
}

export const webHosts = {
  development: 'localhost:8081',
  staging: 'staging.pitayaapp.io',
  production: 'pitayaapp.io',
}

export const cookiesDomains = {
  development: 'localhost',
  staging: '.staging.pitayaapp.io',
  production: '.pitayaapp.io',
}
