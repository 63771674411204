import moment from 'moment-timezone'
import { Nullable } from 'types/helpers'
import { DATE_FORMATS, TIME_UNITS_IN_SEC } from 'constants/dateTime.constants'

interface TimeLeft {
  weeks: number
  days: number
  hours: number
  minutes: number
  seconds: number
}

export const getTimeLeftObject = (date: Date): boolean | TimeLeft => {
  let diff = (Date.parse(String(new Date(date))) - Date.parse(String(new Date()))) / 1000

  /*
    When date is reached return
    false (for clearing countdown)
  */
  if (diff <= 0) {
    return false
  }

  const timeLeft: TimeLeft = {
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  }

  if (diff >= TIME_UNITS_IN_SEC.week) {
    timeLeft.weeks = Math.floor(diff / TIME_UNITS_IN_SEC.week)
    diff -= timeLeft.weeks * TIME_UNITS_IN_SEC.week
  }
  if (diff >= TIME_UNITS_IN_SEC.day) {
    timeLeft.days = Math.floor(diff / TIME_UNITS_IN_SEC.day)
    diff -= timeLeft.days * TIME_UNITS_IN_SEC.day
  }
  if (diff >= TIME_UNITS_IN_SEC.hour) {
    timeLeft.hours = Math.floor(diff / TIME_UNITS_IN_SEC.hour)
    diff -= timeLeft.hours * TIME_UNITS_IN_SEC.hour
  }
  if (diff >= TIME_UNITS_IN_SEC.minute) {
    timeLeft.minutes = Math.floor(diff / TIME_UNITS_IN_SEC.minute)
    diff -= timeLeft.minutes * TIME_UNITS_IN_SEC.minute
  }

  timeLeft.seconds = diff

  return timeLeft
}

export const formatDateTime = (time: string, format?: Nullable<string>, timezone?: Nullable<string>): string => {
  if (!time) {
    return ''
  }

  const form = format || DATE_FORMATS.DDMMYYYYHHmm
  let momentD = moment(time, moment.ISO_8601)

  if (timezone) {
    momentD = momentD.tz(timezone)
  }

  return momentD.format(form)
}

export const parseShrinkDate = (period = ''): { letter: string; number: string } => {
  const [number, letter] = period.split(/(\d+)/).filter(Boolean)

  return {
    number,
    letter,
  }
}

export const concatParsedShrinkDate = (str: string): string => {
  const { number, letter } = parseShrinkDate(str)
  const single = Number(number) === 1
  let localeKey = ''

  if (letter === 'd') localeKey = single ? 'day' : 'days'
  if (letter === 'm') localeKey = single ? 'month' : 'months'
  if (letter === 'y') localeKey = single ? 'year' : 'years'

  return `${number} LOCALE.${localeKey}`
}

export const in10000Days = (): Date => {
  const date = new Date()

  date.setTime(date.getTime() + 10000 * 24 * 60 * 60 * 1000)

  return date
}

// get format of timezone: GTM+8, GTM, GTM-3
export const getGMTTimezone = (timeZoneName) => {
  const momentTimezone = moment().tz(timeZoneName).format('Z') // +05:00, -08:00, 00:00
  const timezoneNumber = parseInt(momentTimezone)

  if (timezoneNumber === 0) {
    return 'GMT'
  } else if (timezoneNumber < 0) {
    return `GMT${timezoneNumber}`
  } else {
    return `GMT+${timezoneNumber}`
  }
}

export const isIsoDate = (str) => {
  if (!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(str)) return false
  const d = new Date(str)
  return d.toISOString() === str
}

export const increaseDay = (d) => moment(d, DATE_FORMATS.INPUT).add(1, 'd').toISOString()

export const formatForInput = (time) => moment(time, DATE_FORMATS.DDMMYYYY).format(DATE_FORMATS.INPUT)

export const isValidDateValue = (time) => moment(time, DATE_FORMATS.DDMMYYYY).isValid()

const setDateFormat = (format) => (date, currentFormat) => moment(date, currentFormat).format(format)

export const [createFormattedDateTime, createFormattedDate, formatToDefault] = [
  DATE_FORMATS.DDMMYYYYHHmm,
  DATE_FORMATS.DDMMYYYY,
  DATE_FORMATS.default,
].map(setDateFormat)

export const getTwoCharactersTime = (time) => (time.toString().length < 2 ? `0${time}` : time)

export const convertMillisecondsToTimeString = (milliseconds) => {
  let seconds = Math.floor(milliseconds / 1000)
  let minute = Math.floor(seconds / 60)
  const hour = getTwoCharactersTime(Math.floor(minute / 60))
  seconds = getTwoCharactersTime(seconds % 60)
  minute = getTwoCharactersTime(minute % 60)

  return `${hour}:${minute}:${seconds}`
}

export const formatTimeForCountdown = (value) => (Number(value) < 10 ? '0'.concat(value) : value)

export const getDifferenceOfTime = (startDate, endDate, getHours = false) => {
  const difference = new Date(endDate).getTime() - new Date(startDate).getTime()

  if (getHours) {
    return Math.ceil(difference / (1000 * 3600))
  }

  return Math.ceil(difference / (1000 * 3600 * 24))
}
